import { BaseModel } from './base.model';
export class DataModel extends BaseModel{
    label: string;
    id:string;
    name: string;
    fields: Field[];
    groups: any[];
    version:number;
    process:string;
    locked:boolean;
    type:string;
    subEntity:boolean;
    entCount:number;
    priorityWiseCount:{};
    validators: ValidatorInstance[];
    allowView: boolean;
    allowCreate: boolean;
    allowUpdate: boolean;
    allowDelete: boolean;

  constructor() {
    super();

    this.label = '';
    this.name = '';
    this.fields = [];
    this.version = 0;
    this.process = '';
    this.validators = [];
    this.type = '';
    this.locked = false;
    this.subEntity = false;
    this.entCount = 0;
  }
}

export class Field{
  label:string;
  name:string;
  validators:ValidatorInstance[];
  extractors:ExtractorInstance[];
  modelName:string;
  locked:boolean;
  type:string;
  list:boolean;
  expression: string;
  sortOrder:number;
  dataPointName:string;
  value:any;
  inputSource: string[];
  mask:string;
  validationExpression:string;
  placeholder:string;
  groupName:string;
  errorMessage:string;
  hide:boolean;
  mandatory:boolean;
  secured:boolean;
  referenceModel:string;
  lookupEntityList: any[];
  dependencyExpression:string;
  dependencyExpressionList: DependencyExpression[];
  dateTypeName:string;
  viewEntity:boolean;
  disable: boolean;
  lookupType: string;
  parentAttributeCode: string;
  businessKey:boolean;
  uiProperties: any;
  loader: boolean;
  downloadProgressBar: boolean;
  downloadProgressPercentage: number;
  uploadProgressBar: boolean;
  uploadProgressPercentage: number;
  timeValue:any;
  ocrMeta:any;
  unique:boolean;
  inputSourceRoute:string;
  functionInstanceNameForFile: string;
  publicFile: boolean;

  constructor(){
      this.label = '';
      this.name = '';
      this.validators = [];
      this.extractors = [];
      this.modelName = '';
      this.list = false;
      this.sortOrder = 0;
      this.locked = false;
      this.expression = '';
      this.type = '';
      this.dataPointName = '';
      this.inputSource = [];
      this.mask = '';
      this.validationExpression = '';
      this.placeholder = '';
      this.errorMessage = '';
      this.mandatory = false;
      this.secured = false;
      this.referenceModel = '';
      this.lookupEntityList = [];
      this.dependencyExpression = null;
      this.dependencyExpressionList = [];
      this.hide = false;
      this.dateTypeName = '';
      this.disable = false;
      this.businessKey = false;
      this.uiProperties = {};
      this.loader = false;
      this.downloadProgressBar = false;
      this.downloadProgressPercentage = 0;
      this.uploadProgressBar = false;
      this.uploadProgressPercentage = 0;
      this.unique = false;
      this.inputSourceRoute = '';
      this.publicFile = false;
  }
}

export class DependencyExpression {
  sequence: number;
  triggerEvent: string;
  expression: string;
  targetField: string;
  action: string;
  message: string;

  constructor() {
    this.sequence = 0;
    this.triggerEvent = '';
    this.expression = '';
    this.targetField = '';
    this.action = '';
    this.message = '';
  }
}

export class ValidatorInstance{
    name:string;
    params:any;
    constructor(){
        this.name = "";
        this.params = {};
    }
}

export class ExtractorInstance{
    name:string;
    params:any;
    constructor(){
        this.name = "";
        this.params = {};
    }
}

export class Entity extends BaseModel {
    label: string;
    name: string;
    fields: Field[];
    version: number;
    process: string;
    locked: boolean;

  constructor() {
    super();

    this.label = '';
    this.name = '';
    this.fields = [];
    this.version = 0;
    this.process = '';
    this.locked = false;
  }
}

export class LinkageModel extends BaseModel {
  parentDataModelName: string;
  parentId: string;
  childDataModelName: string;
  parentMatadata: LinkageMetadata;
  childMetadataList: LinkageMetadata[];

  constructor() {
    super();

    this.parentDataModelName = '';
    this.parentId = '';
    this.childDataModelName = '';
    this.parentMatadata = new LinkageMetadata();
    this.childMetadataList = [];
  }
}

export class LinkageMetadata {
  entityId: string;
  modelName: string;
  statusCd: string;
  createdBy: string;
  lastUpdatedBy: string;
  createdUserId: string;
  lastUpdatedUserId: string;
  createdAt: Date;
  lastUpdatedAt: Date;

  constructor() {
    this.entityId = '';
    this.modelName = '';
    this.statusCd = '';
    this.createdBy = '';
    this.lastUpdatedBy = '';
    this.createdUserId = '';
    this.lastUpdatedUserId = '';
    this.createdAt = new Date();
    this.lastUpdatedAt = new Date();
  }
}

export class EntitySearchCriteria {
  searchText: string;
	criteria: any;
	sort: any;

  constructor() {
    this.searchText = '';
    this.criteria = {};
    this.sort = {};
  }
}

export class RecordSearchMap{
  recordSearchColumn: string;
  recordColumnValue: any;
  minRange = Number;
  maxRange = Number;
  fromDate: string;
  toDate: string;
  inputSource: any[];
  recordType: string;

  constructor(){
    this.recordSearchColumn = '';
    this.recordColumnValue = null;
    this.inputSource = [];
    this.fromDate = '';
    this.toDate = '';
    this.recordType = '';
  }
}